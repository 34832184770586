import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { OKTA_AUTH } from '@okta/okta-angular';
import { LocalStorageKey } from '../../../shared/constants/local-storage-key.const';

@Injectable({
  providedIn: 'root',
})
export class AdiHomeSloService {
  oktaAuth = inject(OKTA_AUTH);

  constructor(private http: HttpClient) {}

  logoutApiCall() {
    const apiEndPoint = `${environment.API_BE_ENDPOINT}/auth/logout`;
    return this.http.post(apiEndPoint, {});
  }

  postLogoutOperations() {
    const items = { ...localStorage };
    this.clearingClinicConnectSessionStorage();
    for (const keys in items) {
      if (
        !keys.endsWith('_persisted') &&
        keys !== LocalStorageKey.SELECTED_LANGUAGE &&
        keys !== 'adiErrorPopup'
      ) {
        localStorage.removeItem(keys);
      }
    }
  }

  clearingClinicConnectSessionStorage() {
    sessionStorage.removeItem('cc_account_facility_name');
    sessionStorage.removeItem('cc_account_practice_org');
    sessionStorage.removeItem('cc_access_user_role');
    sessionStorage.removeItem('cc_access_user_status');
  }
}
