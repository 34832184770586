import { EnvConfig } from './model/environment.model';

export const environment: EnvConfig = {
  production: true,
  TEALIUM_ACCOUNT: 'alcon',
  TEALIUM_PROFILE: 'adi-platform',
  TEALIUM_ENV: 'dev',
  OKTA_CONFIGS: {
    ADI_HOME: {
      CLIENT_ID: '0oadoup28hhYPtA8u1d7',
      ISSUER_URL: 'https://dev2-axon.myalcon.com/oauth2/aus5kk17b3KfCwjA91d7',
    },
    CLINIC_CONNECT: {
      CLIENT_ID: '0oab4ew3xkER8ULS71d7',
      ISSUER_URL: 'https://dev2-axon.myalcon.com/oauth2/aus5kk17b3KfCwjA91d7',
    },
    INSIGHTS_CENTER: {
      CLIENT_ID: '0oaf08bfd1lHQ7N1x1d7',
      ISSUER_URL: 'https://dev2-axon.myalcon.com/oauth2/aus5kk17b3KfCwjA91d7',
    },
  },
  API_BE_ENDPOINT: 'https://adi.dev.myalcon.com/api',
  STORE_URL: 'https://qa1.digital.myalcon.com/store/directory',
  STORE_ENROLL_URL: 'https://qa1.digital.myalcon.com/alconUS/en_US?origin=home',
  CHANGE_PASSWORD: 'https://d-identity.myalcon.com/myaccount',
  ENV: 'dev',
};
