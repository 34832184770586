{
    "login": {
        "customerButton": "Login as customer",
        "getStarted": "GET STARTED",
        "login": "LOG IN",
        "title": "Surgical Business Operations Solutions",
        "subtitle": "Discover how we’re helping people see brilliantly by helping surgical practices run brilliantly.",
        "operationalChallenges": "Start solving some of your biggest operational challenges",
        "operationalDescription": "With our fully integrated Adi solutions, you can break bottlenecks, enhance efficiency, and optimize critical workflows across your surgical eye care practice.",
        "operationalSolutions": "Operational solutions to explore",
        "comingSoonTitle": "Coming soon: Adi surgical workflow solutions",
        "comingSoonDescription": "The next generation of Adi solutions will bring a new level of precision and personalization to surgical planning, patient engagement, and more. Check back soon for updates.",
        "newToAdiTitle": "Browse available solutions",
        "newToAdiSubtitle": "Create your MyAlcon ID to select the right Adi solutions for your surgical practice.",
        "currentAdiTitle": "Log in with your MyAlcon ID",
        "currentAdiSubtitle": "Access solutions you’ve enrolled in and browse more Adi solutions that are available in your area.",
        "newToAdi": "NEW TO ADI?",
        "CurrentAdiUser": "Current Adi User?",
        "RequestAccess": "REQUEST ACCESS",
        "loginToAdi": "Log In To Adi",
        "alconTeamMember": "Alcon team member? Log in here.",
        "alconTeamMemberSubtitle": "Share available Adi solutions, start a customer demo, or manage enrolled users in your region.",
        "employeeLogin": "ALCON EMPLOYEE LOGIN",
        "adiSolutuionsTitle": "See where Adi solutions are available",
        "adiSolutuionsSubtitle": "They’re launching in select markets worldwide, with more on the way.",
        "checkAvailabilty": "Check Availability",
        "operationalSolutionsText": "Currently available in the United States only.",
        "storeCustomer": "Adi Online Store customer?",
        "storeCustomerDescription": "Available in select markets worldwide, with more on the way.",
        "goToStore": "Go to Adi Online Store"
    },
    "footer": {
        "contactUs": "Contact Us",
        "shippingAndHandling": "Shipping & Handling",
        "aboutAlcon": "About Alcon",
        "termsAndCondition": "Terms & Conditions",
        "privacyNotice": "Privacy Notice",
        "cookieNotice": "Cookie Notice",
        "exerciseYourRights": "Exercise Your Rights",
        "languageSelector": {
            "en": "English",
            "fr": "French",
            "jp": "Japanese",
            "es": "Spanish"
        }
    },
    "popup": {
        "title": "An error occurred while fetching your user profile.",
        "body": "Try again later or contact Customer Service.",
        "buttonTxt": "Close"
    }
}