import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { LocalStorageKey } from './app/shared/constants/local-storage-key.const';
import { v4 as uuidv4 } from 'uuid';

if (environment.production) {
  enableProdMode();
}

(function () {
  if (!localStorage.getItem(LocalStorageKey.TRACE_ID)) {
    localStorage.setItem(LocalStorageKey.TRACE_ID, uuidv4());
  }
})();

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
