import { ErrorHandler, Injectable } from '@angular/core';
import { AdiHomeLoggingService } from '../adi-home-logging/adi-home-logging.service';
import { LogLevel } from '../../constants/log-type.consts';
import { HttpErrorResponse } from '@angular/common/http';
import { LogObject } from '../../interfaces/logging.interface';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private loggingService: AdiHomeLoggingService) {}

  handleError(error: HttpErrorResponse): void {
    const logObj: LogObject = {
      eventType: 'Application Level Error',
      eventDetail: error.message,
      logLevel: LogLevel.ERROR,
    };
    this.loggingService.sendFrontendLogs(logObj).subscribe();
    throw error;
  }
}
