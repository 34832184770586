import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { LocalStorageKey } from '../../../../shared/constants/local-storage-key.const';
import { JwtDecodeService } from '../../../../shared/services/jwt-decode-service/jwt-decode.service';
import { AdiHomeLoggingService } from '../../../../shared/services/adi-home-logging/adi-home-logging.service';
import { LogLevel } from '../../../../shared/constants/log-type.consts';
import { AdiUserDetailsService } from '../../../../shared/services/adi-user-details/adi-user-details.service';
import { UserDetails } from '../../../../shared/interfaces/userDetails.interface';

@Component({
  selector: 'adi-adi-login-callback',
  templateUrl: './adi-login-callback.component.html',
})
export class AdiLoginCallbackComponent implements OnInit {
  private oktaAuth = inject(OKTA_AUTH);
  private jwtDecode = inject(JwtDecodeService);
  private loggingService = inject(AdiHomeLoggingService);
  destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private userDetailsService: AdiUserDetailsService
  ) {}

  async ngOnInit(): Promise<void> {
    let language = localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE);

    if (this.oktaAuth.isLoginRedirect()) {
      // callback flow
      try {
        await this.oktaAuth.storeTokensFromRedirect();
        this.oktaAuth.isAuthenticated().then((isAuthenticated) => {
          if (isAuthenticated) {
            const token = this.oktaAuth.getAccessToken();
            language = this.setLocale(token!, language!);

            this.logData('Login', LogLevel.INFO, 'User logged in successfully');
            this.redirecUser(language!);
          }
        });
      } catch (err) {
        this.logData(
          'Login',
          LogLevel.ERROR,
          `User not logged in successfully - ${err}`
        );
        this.redirectToOktaLogin();
      }
    } else {
      this.logData('Login', LogLevel.INFO, `User not logged in successfully`);
      this.router.navigate([`${language}/login`]);
    }
  }

  async redirectToOktaLogin() {
    await this.oktaAuth.signInWithRedirect();
  }

  async redirecUser(language: string) {
    if (
      localStorage.getItem(LocalStorageKey.LAST_VISITED_PATH) &&
      !localStorage
        .getItem(LocalStorageKey.LAST_VISITED_PATH)
        ?.includes('login')
    ) {
      this.router.navigate([
        `${localStorage.getItem(LocalStorageKey.LAST_VISITED_PATH)}`,
      ]);
    } else {
      await this.userDetailsService.callUserDetails();
      this.userDetailsService
        .getCurrentUserDetails()
        .subscribe((res: UserDetails | undefined) => {
          if (res && res.associatedAccounts) {
            if (res.associatedAccounts.length === 1) {
              const account = res.associatedAccounts[0];
              localStorage.setItem(
                LocalStorageKey.SELECTED_ACCOUNT_ID,
                account.sapId
              );
              localStorage.setItem('adi-selected-account-name', account.name);
              localStorage.setItem(
                LocalStorageKey.SELECTED_PRACTICE_ORG_ID,
                account.practiceOrg
              );
              localStorage.setItem(
                LocalStorageKey.SELECTED_SALESORG_ID,
                account.salesOrg
              );
              localStorage.setItem(
                LocalStorageKey.SELECTED_ACCOUNT_TYPE,
                account.type
              );

              this.router.navigate([
                `${LocalStorageKey.SELECTED_LANGUAGE}/dashboard`,
              ]);
            } else {
              this.router.navigate([`${language}/account-selector`]);
            }
          }
        });
    }
  }

  logData(eventType: string, logLevel: LogLevel, eventDetail: string) {
    this.loggingService
      .applicationLoadEventLogs(eventType, logLevel, eventDetail)
      .subscribe();
  }

  setLocale(token: string, language: string) {
    const localeFromToken = this.jwtDecode.parseJwt(token)?.gen_locale;
    if (language?.split('_')[0] === localeFromToken.split('_')[0]) {
      localStorage.setItem(LocalStorageKey.SELECTED_LANGUAGE, localeFromToken);
      language = localeFromToken;
    }
    return language;
  }
}
