import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdiMarketingLoginPageComponent } from './components/adi-marketing-login-page/adi-marketing-login-page.component';
import { AdiLoginCallbackComponent } from './components/adi-login-callback/adi-login-callback.component';

const routes: Routes = [
  {
    path: '',
    component: AdiMarketingLoginPageComponent,
  },
  {
    path: 'callback',
    component: AdiLoginCallbackComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdiMarketingLoginPageRoutingModule {}
