import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WindowLocationService {
  openLink(url: string) {
    window.location.assign(url);
  }
}
