import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JwtDecodeService {
  parseJwt(token?: string): any {
    if (token) {
      let jsonPayload = '';
      const base64Url = token?.split('.')[1];
      const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
      if (base64) {
        jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
        );
      }
      return JSON.parse(jsonPayload);
    } else {
      return undefined;
    }
  }
}
