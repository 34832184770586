export const LocalStorageKey = {
  SELECTED_LANGUAGE: 'adi-selected-locale',
  SELECTED_LANGUAGE_NAME: 'adi-selected-locale-name',
  TRACE_ID: 'adi-trace-id',
  LAST_VISITED_PATH: 'adi-last-visited-path',
  SELECTED_ACCOUNT_ID: 'adi-selected-account-id',
  SELECTED_SALESORG_ID: 'adi-selected-salesorg-id',
  SELECTED_PRACTICE_ORG_ID: 'adi-selected-practiceorg-id',
  SELECTED_ACCOUNT_TYPE: 'adi-selected-account-type',
  CURRENT_USER_DETAILS: 'adi-home-current-user-details',
};
