<footer class="footer">
  <div class="footerContainer">
    <!-- Language Selector For Mobile -->
    <div class="languageSelector d-md-none justify-content-center">
      <div class="globe-icon">
        <img src="../../../../../assets/images/globe.svg" alt="Globe" />
      </div>
      <div class="language OneLinkNoTx">{{ selectedLanguage }}</div>
    </div>
    <!-- Footer Links Section -->
    <div class="footerLinks">
      @for (links of footerLinks; track links.name; let index = $index) {
        <div class="link">
          <a href="{{ links.link }}" target="_blank">{{ links.name }}</a>
        </div>

        @if (index < footerLinks.length - 1) {
          <span class="dot d-none d-md-block">.</span>
        }
      }
    </div>
    <div class="footerWebsiteDetails">
      <!-- Language Selector For Desktop,Tablet -->
      <div class="languageSelector d-none d-md-flex">
        <div class="globe-icon">
          <img src="../../../../../assets/images/globe.svg" alt="Globe" />
        </div>
        <div class="language OneLinkNoTx">{{ selectedLanguage }}</div>
      </div>
      <!-- Website Details -->
      <div class="websiteDetails forMobile">
        <div class="websiteLogo">
          <img
            src="https://alcon.widen.net/content/zw1zquqdz4/webp/Adi%20Logo_Footer_Optimized.webp?crop=false&position=c&u=uxrrnh&w=96&h=48"
            alt=""
            class="img-position"
          />
        </div>
        <div class="copyright">&copy; {{ currentYear }} Alcon</div>
        <div class="veevaCode">GLB-ACP-2300001</div>
      </div>

      <div class="websiteDetails forDesktop">
        <div class="copyright">&copy; {{ currentYear }} Alcon</div>
        <div class="veevaCode">GLB-ACP-2300001</div>
        <div class="websiteLogo">
          <img
            src="https://alcon.widen.net/content/zw1zquqdz4/webp/Adi%20Logo_Footer_Optimized.webp?crop=false&position=c&u=uxrrnh&w=96&h=48"
            alt=""
            class="img-position"
          />
        </div>
      </div>
    </div>
  </div>
</footer>
