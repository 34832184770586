<div class="loader-container">
  <div class="card">
    <div class="card-content">
      <div class="card-info">
        <p>
          {{
            bodyMessage ? bodyMessage : 'Please wait while we redirect you...'
          }}
        </p>
      </div>
      <div class="card-spinner">
        <div class="spinner"></div>
      </div>
      <div class="subDesc">
        <span class="submenutext">It may take some seconds...</span>
      </div>
    </div>
  </div>
</div>
