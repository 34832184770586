import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { GuestLoginData } from '../../../shared/interfaces/guestLoginData.interface';

@Injectable({
  providedIn: 'root',
})
export class GuestLoginService {
  constructor(private http: HttpClient) {}

  getLoginPageData(): Observable<GuestLoginData> {
    const apiEndPoint = `${environment.API_BE_ENDPOINT}/guest/guestConfig`;
    return this.http.get<GuestLoginData>(apiEndPoint);
  }

  updateData() {
    const apiEndPoint = `${environment.API_BE_ENDPOINT}/auth/users`;
    return this.http.post(apiEndPoint, {});
  }
}
