import { Component, Input } from '@angular/core';
@Component({
  selector: 'adi-loader',
  standalone: true,
  imports: [],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
})
export class LoaderComponent {
  @Input() bodyMessage: string = '';
}
