import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { LocalStorageKey } from '../../constants/local-storage-key.const';
import { AdiHomeSloService } from '../../../features/adi-home-slo/services/adi-home-slo.service';

export const AdiNoAuthGuard: CanActivateFn = (route, state) => {
  const oktaAuth = inject(OKTA_AUTH);
  const router = inject(Router);
  const sloService = inject(AdiHomeSloService);
  return oktaAuth.isAuthenticated().then((isAuthenticated) => {
    if (isAuthenticated) {
      if (localStorage.getItem(LocalStorageKey.LAST_VISITED_PATH)) {
        router.navigate([
          localStorage.getItem(LocalStorageKey.LAST_VISITED_PATH),
        ]);
      } else {
        router.navigate([
          `${localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE) ?? 'en_US'}/dashboard`,
        ]);
      }
      return false;
    } else {
      if (localStorage.getItem(LocalStorageKey.SELECTED_ACCOUNT_ID)) {
        sloService.postLogoutOperations();
      }
      return true;
    }
  });
};
