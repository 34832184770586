import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { JwtDecodeService } from '../../services/jwt-decode-service/jwt-decode.service';
import { environment } from '../../../../environments/environment';
import { LocalStorageKey } from '../../constants/local-storage-key.const';

export const AdiAuthGuard: CanActivateFn = (route, state) => {
  const oktaAuth = inject(OKTA_AUTH);
  const router = inject(Router);
  const jwtDecodeService = inject(JwtDecodeService);

  return oktaAuth.isAuthenticated().then(async (isAuthenticated) => {
    if (
      !state.url.includes('account-selector') &&
      !state.url.includes('logout')
    ) {
      localStorage.setItem(LocalStorageKey.LAST_VISITED_PATH, state.url);
    }
    if (isAuthenticated) {
      const oktaToken = oktaAuth.getAccessToken();
      if (oktaToken) {
        const decodedOktaToken = jwtDecodeService.parseJwt(oktaToken);
        if (
          environment.OKTA_CONFIGS.ADI_HOME.CLIENT_ID !== decodedOktaToken.cid
        ) {
          await oktaAuth.signInWithRedirect();
          return false;
        } else {
          return true;
        }
      } else {
        router.navigate([
          `${localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE) ?? 'en_US'}/login`,
        ]);
        return false;
      }
    } else {
      router.navigate([
        `${localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE) ?? 'en_US'}/login`,
      ]);
      return false;
    }
  });
};
