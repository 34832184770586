import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AssociatedAccounts } from '../../../shared/interfaces/userDetails.interface';

@Injectable({
  providedIn: 'root',
})
export class AccountSelectorService {
  
  private accountsSource = new BehaviorSubject<AssociatedAccounts[]>([]);
  currentAccounts = this.accountsSource.asObservable();

  setAccounts(accounts: AssociatedAccounts[]) {
    this.accountsSource.next(accounts);
  }
}
