import {
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { OktaAuthModule } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { environment } from '../environments/environment';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { HttpInterceptorService } from './shared/interceptors/http-interceptor.service';
import { GlobalErrorHandlerService } from './shared/services/global-error-handler/global-error-handler.service';
import { LocalStorageKey } from './shared/constants/local-storage-key.const';

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      OktaAuthModule.forRoot({
        oktaAuth: new OktaAuth({
          issuer: environment.OKTA_CONFIGS.ADI_HOME.ISSUER_URL,
          clientId: environment.OKTA_CONFIGS.ADI_HOME.CLIENT_ID,
          redirectUri: `${window.location.origin}/login/callback`,
          postLogoutRedirectUri: `${window.location.origin}/${localStorage.getItem(LocalStorageKey.SELECTED_LANGUAGE)}/login`,
          scopes: ['openid', 'offline_access', 'profile', 'email'],
          tokenManager: {
            storage: 'cookie',
            storageKey: environment.OKTA_CONFIGS.ADI_HOME.CLIENT_ID,
          },
          cookies: {
            secure: true,
          },
        }),
      })
    ),
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandlerService,
    },
  ],
};
