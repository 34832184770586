import { CanActivateFn, Router } from '@angular/router';
import { LocalStorageKey } from '../../constants/local-storage-key.const';
import { inject } from '@angular/core';

export const AccountAuthGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  if (localStorage.getItem(LocalStorageKey.SELECTED_ACCOUNT_ID)) {
    return true;
  } else {
    router.navigate([`${LocalStorageKey.SELECTED_LANGUAGE}/account-selector`]);
    return false;
  }
};
