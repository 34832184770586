import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdiMarketingLoginPageComponent } from './components/adi-marketing-login-page/adi-marketing-login-page.component';
import { AdiMarketingLoginPageRoutingModule } from './adi-marketing-login-page-routing.module';
import { FooterComponent } from './components/footer/footer.component';
import { AdiLoginCallbackComponent } from './components/adi-login-callback/adi-login-callback.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { GuestLoginService } from './services/guest-login.service';
import { WindowLocationService } from '../../shared/services/window-location-service/window-location.service';
import { PopupComponent } from '../../shared/components/popup/popup/popup.component';

@NgModule({
  declarations: [
    AdiMarketingLoginPageComponent,
    AdiLoginCallbackComponent,
    FooterComponent,
  ],
  exports: [AdiMarketingLoginPageComponent, FooterComponent],
  imports: [
    CommonModule,
    FormsModule,
    AdiMarketingLoginPageRoutingModule,
    HttpClientModule,
    LoaderComponent,
    PopupComponent,
  ],
  providers: [GuestLoginService, WindowLocationService],
})
export class AdiMarketingLoginPageModule {}
