import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Observable, catchError, throwError } from 'rxjs';
import { LocalStorageKey } from '../constants/local-storage-key.const';
import { Router } from '@angular/router';
import { AdiHomeLoggingService } from '../services/adi-home-logging/adi-home-logging.service';
import { LogLevel } from '../constants/log-type.consts';
import { LogObject } from '../interfaces/logging.interface';
import { EXCLUDED_URLS } from '../constants/excluded-urls.const';
import { UserDetailsService } from '../../features/user-management/services/user-details-service/user-details.service';
@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  private oktaAuth = inject(OKTA_AUTH);
  private router = inject(Router);
  private loggingService = inject(AdiHomeLoggingService);
  private userService = inject(UserDetailsService)
  excludedUrls = EXCLUDED_URLS;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const oktaToken: string | null = this.oktaAuth.getAccessToken() ?? null;
    const idToken = this.oktaAuth.getIdToken();
    const selectedLocale = localStorage.getItem(
      LocalStorageKey.SELECTED_LANGUAGE
    );
    request = this.cloneRequestHeader(oktaToken, idToken, request);

    if (
      request.url.includes(window.location.origin) ||
      window.location.origin.includes('localhost')
    ) {
      if (
        (request.method === 'POST' && request.url.includes('contactus')) ||
        request.url.includes('logging')
      ) {
        return next.handle(request);
      } else {
        return next.handle(request).pipe(
          catchError((error: HttpErrorResponse) => {
            this.logError(error);
            return this.handleError(error, selectedLocale!, request);
          })
        );
      }
    }

    return next.handle(request);
  }

  cloneRequestHeader(oktaToken: any, idToken: any, request: any) {
    if (oktaToken) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${oktaToken}`,
        },
      });
    }

    if (idToken) {
      request = request.clone({
        setHeaders: {
          'X-Adi-Identity': idToken ?? '',
        },
      });
    }

    request = request.clone({
      setHeaders: {
        'X-Adi-Source': 'adi-platform',
        'X-Adi-Trace-Id': localStorage.getItem(LocalStorageKey.TRACE_ID)!,
      },
      withCredentials: false,
    });

    return request;
  }

  logError(error: HttpErrorResponse) {
    const logObj: LogObject = {
      eventType: 'Application Level Error',
      eventDetail: error.message,
      logLevel: LogLevel.ERROR,
    };
    this.loggingService.sendFrontendLogs(logObj).subscribe();
  }

  handleError(
    error: HttpErrorResponse,
    selectedLocale: string,
    request: HttpRequest<any>
  ) {
    const skipRedirection = this.userService.skipRedirection
    if (error.status !== 401 && error.status !== 404 && error.status !== 409) {
      this.oktaAuth
        .isAuthenticated()
        .then((isAuthenticated) => {
          if (
            !(
              request.url.includes('entitlement') && request.method === 'DELETE'
            ) &&
            !this.isUrlExcluded(request.url)
          ) {
            if (skipRedirection && error.status === 500) {
              this.handleErrorPageNavigation(isAuthenticated, selectedLocale);
            }

          }
        })
        .catch(() => {
          this.router.navigate([`${selectedLocale}/login`]);
        });
    }

    if (error.status === 401) {
      this.router.navigate([`${selectedLocale}/logout`]);
    }
    if (error.status === 500) {
      return throwError(() => error);
    }
    return throwError(() => error);
  }

  handleErrorPageNavigation(isAuthenticated: boolean, selectedLocale: string) {
    if (isAuthenticated) {
      this.router.navigate([`${selectedLocale}/error/errorPage`]);
    } else {
      this.router.navigate([`${selectedLocale}/login`]);
    }
  }

  isUrlExcluded(requestUrl: string) {
    return this.excludedUrls.some((url) => requestUrl.includes(url));
  }
}
